import { BackendFile } from '@/dto/taskmap/Dto';

export const calculateFileSize = (fileSize: number) => {
  return Math.round(fileSize / 1024);
};

export const getFileName = (name: string | undefined) => {
  if (!name) return;
  if (name.length < 25) return name;

  return `...${name.slice(-25)}`;
};

export const getFileExtension = (name: string | undefined) => {
  if (!name) return;
  return `${name.slice(name.lastIndexOf('.'))}`;
};

export const DEFAULT_MAX_FILE_SIZE = 15360; //15МБ
export const DEFAULT_MAX_FILES_AMOUNT = 10;

export const DEFAULT_ACCEPTED_EXTENSIONS = '.pdf, .doc, .docs, .jpeg, .jpg, .png, .xls, .xlsx';

export type FileInputFiles = (File | BackendFile)[];
